
  import {
    IonPage,
    IonRouterOutlet,
  } from '@ionic/vue';
  import { defineComponent } from 'vue'; 

  export default defineComponent({
    components: {
      IonPage,
      IonRouterOutlet,
    },
  });
