import { Clase } from '@/interfaces/Clase';
import { User } from '@/interfaces/User';
import axios from 'axios';

const dbName = 'galidancia';
const dbVersion = 4;

let db: IDBDatabase | null = null;

function openDB(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);
    
    request.onerror = (event) => reject(event);
    
    request.onsuccess = (event) => {
      db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };
    
    request.onupgradeneeded = (event) => {
      db = (event.target as IDBOpenDBRequest).result;
      
      if (!db.objectStoreNames.contains('disciplinas')) {
        const disciplinasStore = db.createObjectStore('disciplinas', { keyPath: 'id', autoIncrement: true });
        disciplinasStore.createIndex('disciplina', 'disciplina', { unique: false });
      }
      
      if (!db.objectStoreNames.contains('profesores')) {
        const profesoresStore = db.createObjectStore('profesores', { keyPath: 'id', autoIncrement: true });
        profesoresStore.createIndex('id', 'id', { unique: false });
      }
      
      if (!db.objectStoreNames.contains('sesionesRatings')) {
        const sesionesRatingsStore = db.createObjectStore('sesionesRatings', { keyPath: 'id', autoIncrement: true });
        sesionesRatingsStore.createIndex('id', 'id', { unique: false });
      }
    };
  });
}

function ensureDB(): Promise<IDBDatabase> {
  if (db) {
    return Promise.resolve(db);
  }
  return openDB();
}

// Funciones CRUD para 'disciplinas'

function getAllDisciplinas(): Promise<Clase[]> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['disciplinas']);
      const objectStore = transaction.objectStore('disciplinas');
      const request = objectStore.getAll();
      
      request.onsuccess = (event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event) => reject(event);
    });
  });
}

function addDisciplina(disciplina: Clase): Promise<IDBValidKey> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['disciplinas'], 'readwrite');
      const objectStore = transaction.objectStore('disciplinas');
      const request = objectStore.add(disciplina);
      
      request.onsuccess = (event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event) => reject(event);
    });
  });
}

function getDisciplina(id: number): Promise<Clase> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['disciplinas']);
      const objectStore = transaction.objectStore('disciplinas');
      const request = objectStore.get(id);
      
      request.onsuccess = (event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event) => reject(event);
    });
  });
}

function updateDisciplina(disciplina: Clase): Promise<void> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['disciplinas'], 'readwrite');
      const objectStore = transaction.objectStore('disciplinas');
      const request = objectStore.put(disciplina);
      
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event);
    });
  });
}

function deleteDisciplina(id: number): Promise<void> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['disciplinas'], 'readwrite');
      const objectStore = transaction.objectStore('disciplinas');
      const request = objectStore.delete(id);
      
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event);
    });
  });
}



// Método para sincronizar disciplinas activas. Trae de remoto y actualiza en local
async function syncDisciplinasActivas() {
  const response = await axios.get('getDisciplinasActivas');
  if (response.data.length > 0) 
    clearDisciplinas(); //vaciamos la tabla antes de sincronizar

  const data = response.data;
  for (const disciplina of data) {
    await addDisciplina(disciplina);
  }
}

// vacia la tabla disciplinas
function clearDisciplinas(): Promise<void> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['disciplinas'], 'readwrite');
      const objectStore = transaction.objectStore('disciplinas');
      const request = objectStore.clear();
      
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event);
    });
  });
}

//Funciones crud para profesores

function getProfesores(): Promise<User[]> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['profesores']);
      const objectStore = transaction.objectStore('profesores');
      const request = objectStore.getAll();
      
      request.onsuccess = (event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event) => reject(event);
    });
  });
}




function addProfesor(profesor: User): Promise<IDBValidKey> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['profesores'], 'readwrite');
      const objectStore = transaction.objectStore('profesores');
      const request = objectStore.add(profesor);
      
      request.onsuccess = (event) => resolve((event.target as IDBRequest).result);
      request.onerror = (event) => reject(event);
    });
  });
}

// vacia la tabla profesores
function clearProfesores(): Promise<void> {
  return ensureDB().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['profesores'], 'readwrite');
      const objectStore = transaction.objectStore('profesores');
      const request = objectStore.clear();
      
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event);
    });
  });
}


// Método para sincronizar profesores. Trae de remoto y actualiza en local
async function syncProfesores() {
  const response = await axios.get('galidancia/profesores');
  if (response.data.length > 0) 
    clearProfesores(); //vaciamos la tabla antes de sincronizar

  const data = response.data;
  for (const profesor of data) {
    await addProfesor(profesor);
  }
}

export {
  openDB,
  addDisciplina,
  getDisciplina,
  updateDisciplina,
  deleteDisciplina,
  syncDisciplinasActivas,
  getAllDisciplinas,
  getProfesores,
  syncProfesores,
  Clase,
};
