
    import { IonIcon,IonNote,IonImg,IonItem, IonLabel,IonTitle,IonToolbar,IonHeader,IonMenuToggle,IonContent,IonMenu } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { currentUserStore } from '@/stores/currentUser'
    import userAuth from '@/composables/userAuth';
    import tools from "@/composables/tools";
import { calendarOutline, calculatorOutline, logoEuro, logoWhatsapp, personOutline } from 'ionicons/icons';
  
    export default defineComponent({
      components: { IonIcon,IonImg,IonNote,IonItem, IonLabel,IonTitle,IonToolbar,IonHeader,IonMenuToggle,IonContent,IonMenu},

      setup() {
        const currentUser = currentUserStore()
        const Auth = userAuth()

        const preload_Tarifas = () => {
          tools().setLog('Pulsa para ver tarifas')
        }

        const preload_Eventos = () => {
          tools().setLog('Pulsa para ver eventos')
        }

        const preload_Coordinadores = () => {
          tools().setLog('Pulsa para ver coordinadores')
        }

        const preload_Profesores = () => {
          tools().setLog('Pulsa para ver profesores')
        }

        const preload_Aulas = () => {
          tools().setLog('Pulsa para ver aulas')
        }

        const preload_Horarios = () => {
          tools().setLog('Pulsa para ver horarios')
        }

        const preload_Probar = () => {
          tools().setLog('Pulsa para ver horarios')
        }

        const preload_Calculadora = () => {
          tools().setLog('Pulsa para ver calculadora')
        }

        const preload_Recuperar = () => {
          tools().setLog('Pulsa para ver horarios')
        }
        const preload_Profile = () => {
          tools().setLog('Pulsa para ver si perfil personal')
        }
                
        const asistencia = ()=>{
          window.open('whatsapp://send?phone=' + process.env.VUE_APP_WHATSAPP_HELP + '&text=' + encodeURI('Tengo problemas con la aplicación. ¿Puedes ayudarme?'))
          tools().setLog('Solicitaron ayuda','warning')
        }
        
        return {
          currentUser,
          Auth,
          userAuth,
          currentUserStore,
          preload_Probar,
          preload_Recuperar,
          preload_Horarios,
          preload_Tarifas,
          preload_Coordinadores,
          preload_Profesores,
          preload_Aulas,
          preload_Profile,
          preload_Eventos,
          preload_Calculadora,
          calendarOutline,
          calculatorOutline,
          logoEuro,
          personOutline,
          logoWhatsapp,
          asistencia,
          tools
        }
      },
    });
  