// stores/currentUser.js

import { defineStore } from 'pinia';
import { User } from '@/interfaces/User';
import { Asiento } from '@/interfaces/Asiento';
import { Clase } from '@/interfaces/Clase';
import { ProductCart } from '@/interfaces/ProductCart';
import { ref } from 'vue';


export const currentUserStore = defineStore('currentUser', {
  state: () => ({
    user: null as User | null,
    clases: [] as Clase[],
    cart: [] as ProductCart[],
    asientos: ref<Asiento[]>([]),
    redirectAfterLogin: null as string | null
  }),

  actions:{
    update(myUser : User)
    {
        this.user = myUser
    }
  }
});