import axios from 'axios';
import tools from "@/composables/tools";
import { currentUserStore } from '@/stores/currentUser'


export abstract class AsientoService{
    static async loadAsientos() {
        try {
            await axios.get('user/pagosAlumno')
            .then( response => {
                currentUserStore().$state.asientos = response.data
            })
        } catch (error) {
            tools().toast('Error al cargar las pagos','danger',5000);
        }
    }
}