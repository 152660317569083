import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, { "content-id": "main-content" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Menú")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu_toggle, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                href: "tabs/eventos",
                onClick: _ctx.preload_Eventos
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.calendarOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Próximos eventos")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/recuperar",
                onClick: _ctx.preload_Recuperar
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    slot: "start",
                    src: "https://api.gest.tecnonet.es/media/icons/recuperar.svg",
                    style: {"width":"25px"}
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Recuperar clases")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/probar",
                onClick: _ctx.preload_Probar
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    slot: "start",
                    src: "https://api.gest.tecnonet.es/media/icons/probar.svg",
                    style: {"width":"22px"}
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Probar clases")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/horarios",
                onClick: _ctx.preload_Horarios
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.calendarOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Horarios")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/calculadora",
                onClick: _ctx.preload_Calculadora
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.calculatorOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Calcula tu tarifa")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "https://gest.tecnonet.es/widget.php?wid=tarifasGalidancia",
                onClick: _ctx.preload_Tarifas
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.logoEuro
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Tarifas")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/profesores",
                onClick: _ctx.preload_Profesores
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    slot: "start",
                    src: "https://api.gest.tecnonet.es/media/icons/profesor.svg",
                    style: {"width":"22px"}
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Profesores")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/coordinadores",
                onClick: _ctx.preload_Coordinadores
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    slot: "start",
                    src: "https://api.gest.tecnonet.es/media/icons/coordinador.svg",
                    style: {"width":"22px"}
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Coordinadores")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/aulas",
                onClick: _ctx.preload_Aulas
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    slot: "start",
                    src: "https://api.gest.tecnonet.es/media/icons/aula.svg",
                    style: {"width":"22px"}
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Aulas")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, {
                href: "tabs/profile",
                onClick: _ctx.preload_Profile
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.personOutline
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Mi cuenta")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_item, { onClick: _ctx.asistencia }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.logoWhatsapp
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Ayuda")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.currentUserStore().$state.user !== null)
        ? (_openBlock(), _createBlock(_component_ion_label, {
            key: 0,
            class: "ion-text-center",
            style: {"margin-bottom":"1em","font-size":"0.9em"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Hola, " + _toDisplayString(_ctx.tools().getShortName(_ctx.currentUserStore().$state.user?.name)) + " ", 1),
              _createVNode(_component_ion_note, { style: {"display":"block","font-size":"x-small"} }, {
                default: _withCtx(() => [
                  _createTextVNode(" Versión " + _toDisplayString(require('../../package.json').version), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}