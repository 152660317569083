
    import { loadingController,IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon,IonTitle,IonToolbar,IonHeader,IonMenuButton,IonButtons } from '@ionic/vue';
    import { homeOutline, walletOutline, peopleCircleOutline,logoWhatsapp, logOutOutline, accessibilityOutline } from 'ionicons/icons';
    import SidebarMenu from '@/components/SidebarMenu.vue'
    import userAuth from '@/composables/userAuth';
    import { AsientoService } from '@/services/AsientoServices';
import { UserServices } from '@/services/UserServices';
import { currentUserStore } from '@/stores/currentUser';
import tools from "@/composables/tools";


    export default {
      components: { SidebarMenu,IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon,IonTitle,IonToolbar,IonHeader,IonMenuButton,IonButtons },
      setup() {

        const preload_Clases= async () => {
          tools().setLog('Pulsaron tab para ver clases')
          tools().showLoading('Cargando mis clases...')
          await UserServices.loadClases()
          loadingController.dismiss()
        }

        const preload_Pagos = async () => {
          tools().setLog('Pulsaron tab para ver pagos')
          showLoading('Cargando últimos pagos...')
          await AsientoService.loadAsientos()
          loadingController.dismiss()
        }

        const preload_Asistencias = async () => {
          tools().setLog('Pulsaron tab para ver asistencias')
        }



        const showLoading = async (msj:string) => {
        const loading = await loadingController.create({
          message: msj,
        });
        
        loading.present();
      }

        return {
          preload_Clases,
          preload_Asistencias,
          preload_Pagos,
          walletOutline,
          peopleCircleOutline,
          homeOutline,
          userAuth,
          logoWhatsapp,
          currentUserStore,
          logOutOutline,
          accessibilityOutline
        };       
      },
    };
  