import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import IndexPage from '../views/IndexPage.vue'
import TabsPage from '../views/TabsPage.vue'


const routes: Array<RouteRecordRaw> = [
  { 
    path: '/',
    redirect: 'tabs/clases',
    meta:{requiredAuth:true}
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/widgets/',
    component: IndexPage,
    children: [
      {
        path: 'registroEventos/:clase_id',
        name: 'registroEventos',
        component: () => import('@/views/Clase/RegistroEventos.vue'),
      },
    ]
  },

  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: 'asientos',
        name: 'Mis pagos',
        component: () => import('@/views/AsientosPage.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'clases',
        component: () => import('@/views/ClasesPage.vue'),
        name: 'Mis clases',
        meta:{requiredAuth:true}
      },
      {
        path: 'calculadora',
        component: () => import('@/views/CalculadoraPage.vue'),
        name: 'Calculadora de precios',
        meta:{requiredAuth:true}
      },
      {
        path: 'coordinadores',
        component: () => import('@/views/Clase/coordinadoresList.vue'),
        name: 'Coordinadores',
      },
      {
        path: 'profesores',
        component: () => import('@/views/Clase/profesoresList.vue'),
        name: 'Profesores',
      },
      {
        path: 'horarios',
        component: () => import('@/views/HorariosPage.vue'),
        name: 'Horarios',
      },
      {
        path: 'eventos',
        component: () => import('@/views/EventosPage.vue'),
        name: 'Próximos eventos',
      },
      {
        path: 'probar',
        component: () => import('@/views/Clase/probarList.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'recuperar',
        component: () => import('@/views/RecuperarPage.vue'),
        name: 'Recuperar',
        meta:{requiredAuth:true}
      },
      {
        path: 'aulas',
        component: () => import('@/views/Clase/aulasList.vue'),
        name: 'Aulas',
      },
      {
        path: 'profile',
        component: () => import('@/views/profilePage.vue'),
        name: 'Yo',
      },
      {
        path: 'asistencias/:clase_id?',
        component: () => import('@/views/Clase/AsistenciasPage.vue'),
        name: 'Mis asistencias',
        meta:{requiredAuth:true}
      },
      {
        path: 'ayudar/:sesion_id/:user_id?',
        component: () => import('@/components/Clase/AyudanteView.vue'),
        name: '¿Puedes ayudar?',
        meta:{requiredAuth:false}
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue'),
        name: 'Galidancia',
        meta:{requiredAuth:true}
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
