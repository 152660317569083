import axios from 'axios';
import { User } from '@/interfaces/User'
import { Clase } from '@/interfaces/Clase'
import tools from "@/composables/tools";
import { currentUserStore } from '@/stores/currentUser';

export abstract class UserServices{

    static async save(myUser:User) : Promise<User|undefined> {
        try {
            const response = await axios.post('user',myUser)
            tools().toast('Se han actualizado los datos correctamente','success',5000)
            return response.data
        } catch (error) {
            tools().toast('No se ha podido guardar los datos del usuario','danger',5000)
        }
    }

    static async loadClases() {
        try {
            const response = await axios.get('user/clases')
            response.data.sort((a:any,b:any) => (a.next_at > b.next_at) ? 1 : ((b.next_at > a.next_at) ? -1 : 0))
            currentUserStore().$state.clases = response.data


        } catch (error) {
            tools().setLog('No se han podido cargar tus clases','error')
            tools().toast('No se han podido cargar tus clases','danger',5000)
            return []
        }
    }

    static async getClasesARecuperar() : Promise<Clase[]> {
        try {
            const response = await axios.get('user/getClasesARecuperar')
            return response.data
        } catch (error) {
            tools().setLog('No se han podido cargar tus clases','error')
            tools().toast('No se han podido cargar tus clases','danger',5000)
            return {} as Clase[]
        }       
    }

    static async getUltimasRecuperaciones() : Promise<Clase[]> {
        try {
            const response = await axios.get('user/getUltimasRecuperaciones')
            return response.data
        } catch (error) {
            tools().setLog('No se han podido cargar la últimas recuperaciones','error')
            tools().toast('No se han podido cargar la últimas recuperaciones','danger',5000)
            return {} as Clase[]
        }       
    }

    static setCart()
    {
        try {
            axios.post('user/cart',currentUserStore().$state.cart)
        } catch (error) {
            tools().setLog('Error al guardar el carrito en el servidor','error')
            tools().toast('No se han podido actualizar el carrito','danger',5000)
            return []
        }       
    }

    static async loadCart()
    {
        try {
            const response = await axios.get('user/cart')
            if(response.data.length > 0)
                currentUserStore().$state.cart = response.data
        } catch (error) {
            tools().setLog('Error al traer el carrito desde servidor','error')
            tools().toast('No se han podido mostrar el carrito','danger',5000)
            return []
        }       
    }
}
