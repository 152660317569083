import { defineStore } from 'pinia';

export const mainStore = defineStore('mainStore', {
  state: () => ({
    loading: {
      show: false,
      message: '',
    },
    isLogged: false,
  }),

  actions: {
    setLoading(loading: boolean, message='') {
      this.loading.show = loading;
      this.loading.message = message;
    },
  }


});